import React from 'react'
import SingleForm from '../../Components/SingleForm'
import { FormArray } from '../../config/SingleFormConfig'
import Layout from '../../Layout/Ui/Layout'

const Page = () => {
  return (
    <Layout>
        <div className='home_container'>
          <img src='/logo.png' alt='' width={180} className='logo'/>
          <div className='form_container'>
            {
              FormArray.map((form:any)=>{
                return <SingleForm imageSrc={"/FormImages/"+form.imageSrc} link={form.link} title={form.title}/>
              })
            }
          </div>
      </div>
    </Layout>
  )
}

export default Page