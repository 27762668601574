import React from 'react'
import { SingleFormProps } from '../config/SingleFormConfig'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'


const SingleForm = ({imageSrc,title, link}:SingleFormProps) => {
  const  {t} = useTranslation();
  return (
    <div className='SingleForm'>
            <img 
            src={imageSrc}
            width={60}/>
            
            <Link className='link' to={link}><p>{t(title)}</p></Link>
    </div>
  )
}

export default SingleForm