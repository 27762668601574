import React from 'react'
import { useTranslation } from 'react-i18next'

const Header = () => {

  const {t} = useTranslation();

  return (
    <div className='header'>
      <p className='phone'>{t("Phone")} :0097477280008 </p>
      <p>{t("Email")} :Info@alnokhba1.com </p>
    </div>
  )
}

export default Header