export interface SingleFormProps{
    imageSrc:string,
    title:string,
    link:string
}


export const FormArray = [
    {imageSrc: '/instagram.png' , link:'https://www.instagram.com/mazad.alnokhba.qatar/?igsh=aXNkcXQ5Z3E3OGUw' , title: 'instagram'},
    {imageSrc: '/Facebook.png' , link:'https://www.facebook.com/Mazad.Alnokhba.QA?mibextid=ZbWKwL' , title: 'facebook'},
    {imageSrc: '/website.png' , link:'/https://malnokhba.qa/' , title: 'website'},
    // {imageSrc: '/locaiton.png' , link:'/' , title: 'location'},
    {imageSrc: '/google-play.webp' , link:'https://play.google.com/store/apps/details?id=com.sdnone.elite_auction' , title: 'googlePlay'},
    {imageSrc: '/AppStore.png' , link:'https://apps.apple.com/app/mazad-alnokhba/id1597116939' , title: 'appStore'},
] 